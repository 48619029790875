<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <g clip-path="url(#clip0_2437_3850)">
      <path d="M15 1L1 15" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1 1L15 15" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2437_3850">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
